<div class="select-adress-card">
  <div class="row select-adress-card-form-container">
    <app-address-autocomplete
      *ngIf="loadingStatus == loadingStatusEnum.success"
      #appAddressAutocomplete
      [hasError]="hasError"
      [errorMessage]="errorMessage"
      [context]="context"
      [withLabel]="withLabel"
      (selectAddressEvent)="onAddressSelected($event)"
      (selectLocalNo)="localNo = $event"
      (onAddressInitEvent)="onAddressInit($event)"
    >
    </app-address-autocomplete>

    <ng-container *ngIf="buttons; else defaultButtons">
      <ng-template *ngTemplateOutlet="buttons"></ng-template>
    </ng-container>
  </div>
</div>

<ng-template #defaultButtons>
  <div
    (click)="findPlace()"
    class="col ui-clickable d-flex justify-content-center align-items-center select-adress-find-button"
  >
    {{ 'tapp-order.select-adress-component.card-find-place-button' | translate }}
  </div>
</ng-template>

<app-unexpected-issue-dialog
  [visible]="displayPosStatusDialog"
  [place]="displayPosStatusDialogPlace"
  (visibleChange)="!$event && closePosStatusDialog()"
></app-unexpected-issue-dialog>
