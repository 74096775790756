<span
  class="dialog-customer-form-title"
  style="display: flex; align-content: center; align-items: center; justify-content: center"
  >{{ 'tapp-order.payment.invoice.form.title' | translate }}</span
>
<form [formGroup]="invoiceForm">
  <div class="top-wrapper">
    <div class="row d-flex">
      <div class="col-12 col-xl-6">
        <div class="form-group p-0 no-arrows invoice-form-search">
          <label for="nip" class="customer-form">{{ 'tapp-order.payment.invoice.form.nip.label' | translate }}</label>
          <input
            #nipInput
            type="number"
            formControlName="nip"
            pInputText
            id="nip"
            placeholder="{{ 'tapp-order.payment.invoice.form.nip.placeholder' | translate }}"
            class="input-search"
          />
          <div class="button-inside-input" (click)="fillInvoiceDataByTaxNumber()">
            {{ 'tapp-order.payment.invoice.form.search-button' | translate }}
            <img src="/assets/icons/search-icon-32x32-blue.png" />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group p-0">
          <label for="name" class="customer-form">{{ 'tapp-order.payment.invoice.form.name.label' | translate }}</label>
          <input
            type="text"
            formControlName="name"
            pInputText
            id="name"
            placeholder="{{ 'tapp-order.payment.invoice.form.name.placeholder' | translate }}"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex">
    <div class="col-12 col-xl-6">
      <div class="form-group p-0">
        <label for="street" class="customer-form">{{
          'tapp-order.payment.invoice.form.street.label' | translate
        }}</label>
        <input
          type="text"
          formControlName="street"
          pInputText
          id="street"
          placeholder="{{ 'tapp-order.payment.invoice.form.street.placeholder' | translate }}"
        />
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="row d-flex">
        <div class="col-6">
          <div class="form-group p-0 no-arrows">
            <label for="streetNo" class="customer-form">{{
              'tapp-order.payment.invoice.form.streetNo.label' | translate
            }}</label>
            <input type="text" formControlName="streetNo" pInputText id="streetNo" placeholder="Wprowadź numer" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group p-0 no-arrows">
            <label for="localNo" class="customer-form">{{
              'tapp-order.payment.invoice.form.localNo.label' | translate
            }}</label>
            <input
              type="text"
              formControlName="localNo"
              pInputText
              id="localNo"
              placeholder="{{ 'tapp-order.payment.invoice.form.localNo.placeholder' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex">
    <div class="col-12 col-xl-6">
      <div class="row d-flex">
        <div class="col-5">
          <div class="form-group p-0">
            <label for="postalCode" class="customer-form">{{
              'tapp-order.payment.invoice.form.postalCode.label' | translate
            }}</label>
            <input
              type="text"
              formControlName="postalCode"
              pInputText
              id="postalCode"
              placeholder="{{ 'tapp-order.payment.invoice.form.postalCode.placeholder' | translate }}"
            />
          </div>
        </div>
        <div class="col-7">
          <div class="form-group p-0">
            <label for="city" class="customer-form">{{
              'tapp-order.payment.invoice.form.city.label' | translate
            }}</label>
            <input
              type="text"
              formControlName="city"
              pInputText
              id="city"
              placeholder="{{ 'tapp-order.payment.invoice.form.city.placeholder' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-group p-0">
        <label for="country" class="customer-form">{{
          'tapp-order.payment.invoice.form.country.label' | translate
        }}</label>
        <input
          type="text"
          formControlName="country"
          pInputText
          id="country"
          placeholder="{{ 'tapp-order.payment.invoice.form.country.placeholder' | translate }}"
        />
      </div>
    </div>
  </div>
</form>
<div class="buttons-container">
  <button class="save-button ui-clickable" (click)="saveInvoiceData()">
    {{ 'tapp-order.payment.invoice.form.save-button' | translate }}
  </button>
  <button class="cancel-button ui-clickable" (click)="closeDialogEmmiter.emit()">
    {{ 'tapp-order.payment.invoice.form.cancel-button' | translate }}
  </button>
</div>
