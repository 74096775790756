import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { AuthService } from '@core/services/auth.service';
import { OrderService } from '@core/services/order.service';
import { PaymentService } from '@core/services/payment.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';

@Component({
  selector: 'app-delivery-payment-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit, OnChanges, OnDestroy {
  private destroyed$ = new Subject<void>();

  public displayDialog: boolean = false;
  public displayCustomerForm: boolean = false;
  public customerForm: FormGroup;
  public isLoading = true;
  public deliveryOrderType = OrderTypeEnum.delivery;

  @Input() isPaymentMode: boolean = true;
  @Input() isDeliveryZone: BehaviorSubject<boolean>;
  @Input() user: UserDataApiModel;
  @Input() isAccountPage: boolean;
  @Input() tableId: string;
  @Input() context: 'account' | 'other' = 'other';

  constructor(
    private userRestService: UserRestService,
    private authService: AuthService,
    private paymentService: PaymentService,
    public orderService: OrderService,
    public paymentFormService: PaymentFormService,
    public dialog: MatDialog,
  ) {}

  @Output() public deliveryAddressStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(): void {
    this.isDeliveryZone?.getValue();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.isLoading = false;
      this.orderService.setCustomerEmail(this.user.email ?? '');
      this.orderService.setCustomerFirstName(this.user.name ?? '');
      this.orderService.setCustomerPhone(this.user.phoneNumber ?? '');
      this.createCustomerForm();
    } else {
      this.isLoading = false;
      this.createCustomerForm();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private createCustomerForm() {
    this.customerForm = new FormGroup({
      name: new FormControl(
        this.orderService.getCustomerFirstName() ? this.orderService.getCustomerFirstName() : null,
        [Validators.required, Validators.maxLength(50)],
      ),
      phone: new FormControl(this.orderService.getCustomerPhone() ? this.orderService.getCustomerPhone() : null, [
        Validators.required,
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}\\s?[0-9\\s]*[0-9]$'),
        Validators.maxLength(20),
      ]),
      email: new FormControl(
        {
          value: this.orderService.getCustomerEmail() ? this.orderService.getCustomerEmail() : null,
          disabled: this.isAccountPage,
        },
        {
          validators: [Validators.required, Validators.email, Validators.maxLength(100)],
          asyncValidators: [this.validateEmail.bind(this)],
        },
      ),
    });
  }

  private fetchUser(): void {
    this.userRestService
      .getUser()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.user = {
            ...this.user,
            deliveryAddress: user.deliveryAddress,
          };
        },
        error: () => {},
      });
  }

  public showCustomerForm() {
    this.displayCustomerForm = true;
  }

  public changeAddress() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
    this.fetchUser();
  }

  public saveCustomerInfo(): void {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      this.orderService.setCustomerFirstName(this.customerForm.controls['name'].value);
      this.orderService.setCustomerPhone(this.customerForm.controls['phone'].value);
      this.orderService.setCustomerEmail(this.customerForm.controls['email'].value);
      if (this.context === 'account' && this.user) {
        this.user.name = this.orderService.getCustomerFirstName();
        this.user.phoneNumber = this.orderService.getCustomerPhone();
        this.user.email = this.orderService.getCustomerEmail();
        this.userRestService.putUser(this.user).pipe(first()).subscribe();
      }
      this.displayCustomerForm = false;
    }
  }

  public cancelCustomerForm(): void {
    this.displayCustomerForm = false;
  }

  get deliveryAddressLabel(): string {
    return this.paymentService.getDeliveryAddressLabel();
  }

  public validateEmail(control: AbstractControl): Observable<ValidationErrors> {
    if (!control.value) {
      return of(null);
    }
    if (!new RegExp('^[a-z0-9._\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,6}$').test(control.value.toLowerCase())) {
      return of({
        custom: 'Podaj poprawny adres e-mail',
      });
    }
    return of(null);
  }

  get city() {
    return this.context == 'account' ? this.user?.deliveryAddress?.city : this.orderService.getDeliveryAddressCity();
  }

  get street() {
    return this.context == 'account'
      ? this.user?.deliveryAddress?.street
      : this.orderService.getDeliveryAddressStreet();
  }

  get buildingNo() {
    return this.context == 'account'
      ? this.user?.deliveryAddress?.buildingNo
      : this.orderService.getDeliveryAddressBuildingNo();
  }

  get localNo() {
    return this.context == 'account'
      ? this.user?.deliveryAddress?.localNo
      : this.orderService.getDeliveryAddressLocalNo();
  }

  openLoginDialog(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    this.dialog.open(LoginModalComponent, {
      panelClass: 'login-form-container',
    });
  }
}
