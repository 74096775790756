<div class="sidebar-wrapper" *ngIf="isOpen; else isClose">
  <div class="sidebar-name">
    <span *ngIf="!isSummaryMode">{{ 'tapp-order.sidebar-basket-header' | translate }}</span>
    <span *ngIf="isSummaryMode">{{ 'tapp-order.sidebar-basket-header-2' | translate }}</span>
  </div>
  <div class="sidebar-list" [class.sidebar-list-summary]="isSummaryMode" #sidebarList>
    <ng-container *ngIf="basketItems.length === 0">
      <div class="empty-basket d-flex align-items-center flex-column justify-content-center h-100">
        <img src="./assets/icons/basket1.png" alt="" />
        <span>{{ 'tapp-order.your-order-no-items' | translate }}</span>
        <span>{{ 'tapp-order.your-order-add-something' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngFor="let item of basketItems; let i = index">
      <app-article-card-expandable
        *ngIf="place"
        [showDescription]="false"
        [basketItem]="item"
        [basketMode]="true"
        [item]="item"
        [renderedInOrderSummary]="false"
        [quantity]="item.CurrentQuantity"
        [showModifyButton]="true"
        [showModifyRedirect]="[place.publicId + '/modify/' + item.BasketItemID]"
        [isSummaryMode]="isSummaryMode"
        [basketItemIndex]="i"
        [discountNames]="discountNames.length ? discountNames[i] : []"
        [paymentMode]="paymentMode"
      >
      </app-article-card-expandable>
    </ng-container>
  </div>
  <ng-container *ngIf="!isSummaryMode; else summaryMode">
    <div class="sidebar-summary-box">
      <div class="location-wrapper">
        <p *ngIf="orderService.tableId">
          {{ 'tapp-order.order-summary-room' | translate }}: {{ orderService.roomName }} <br />
          {{ 'tapp-order.order-summary-table' | translate }}:
          {{ orderService.tableName }}
        </p>

        <p *ngIf="!orderService.tableId">
          {{ 'tapp-order.sidebar-basket-local-info' | translate }}
        </p>
        <p class="primary-color ui-clickable" *ngIf="!orderService.tableId" (click)="changeLocal()">
          {{ placeString ? placeString : null }}
        </p>
      </div>

      <div *ngIf="discountService.data$ | async as discountData" class="promo-code">
        <span class="promo-code__text">{{ 'tapp-order.sidebar-component.discount-code' | translate }}</span>
        <div class="input-wrapper">
          <input
            pInputText
            class="promo-code__input"
            [class.ng-invalid]="discountData.promoCodeStatus === 'not_active'"
            type="text"
            placeholder=""
            [ngModel]="promoCode"
            (ngModelChange)="hanldePromoCodeChange($event)"
          />
          <button
            pButton
            pRipple
            type="button"
            class="p-button p-button-sm p-button-primary"
            [label]="'common.action.activateCode' | translate"
            (click)="activateDiscountCode()"
            [disabled]="!promoCode || discountService.promoCode === promoCode"
          ></button>
        </div>
        <span class="promo-code__tip">
          <small *ngIf="discountData.promoCodeStatus === 'active_used'" class="promo-code__tip--success">
            {{ 'tapp-order.sidebar-component.code-activated' | translate }}
          </small>
          <small *ngIf="discountData.promoCodeStatus === 'not_active'" class="p-error">
            {{ 'tapp-order.sidebar-component.no-active-promotion-for-code' | translate }}
          </small>
          <small *ngIf="discountData.promoCodeStatus === 'active_not_used_other_conditions_missed'" class="p-error">
            {{ 'tapp-order.sidebar-component.promotion-conditions-not-met' | translate }}
          </small>
          <small *ngIf="discountData.promoCodeStatus === 'active_not_used'" class="promo-code__tip--warning">
            {{ 'tapp-order.sidebar-component.code-valid-but-better-promotion-applied' | translate }}
          </small>
        </span>
      </div>
      <div class="divider"></div>
      <div class="food-cost">
        <span>{{ 'tapp-order.sidebar-basket-header' | translate }}:</span>
        <span class="price">{{ basketService.foodTotalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
      </div>
      <div class="delivery-cost" *ngIf="deliveryZone && deliveryZone.deliveryPrice">
        <span>{{ 'tapp-order.order-component.delivery-cost' | translate }}:</span>
        <span class="price">{{ basketService.deliveryCost$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
      </div>
      <span class="amount">{{ 'tapp-order.order-component.quantity' | translate }} {{ basketItemsAmount }}</span>
      <ng-container
        *ngIf="calculateMinOrderPrice() <= 0 || basketService.getOrderType() !== 'delivery'; else minOrderInfo"
      >
        <div
          *ngIf="
            !place?.maxOrderPrice || (basketService.foodTotalPrice$ | async) <= place.maxOrderPrice;
            else overpricedInfo
          "
          class="button"
          [ngClass]="{
            disabled: !isOrderButtonActive || basketService.basketItems.length === 0
          }"
          (click)="checkCreateOrder()"
        >
          <div class="left">
            <i class="ic ic-basket"></i>
            <span class="order-btn">{{ 'tapp-order.ordering-button-text' | translate }}</span>
          </div>
          <span class="right">{{ basketService.finalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
        </div>
        <ng-template #overpricedInfo>
          <span class="p-error">
            {{ 'tapp-order.sidebar-component.max-order-amount' | translate }}
            {{ place.maxOrderPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}
          </span>
        </ng-template>
      </ng-container>
      <ng-template #minOrderInfo>
        <div *ngIf="calculateMinOrderPrice() > 0" class="min-order-info">
          <span>{{ 'tapp-order.order-component.minimal-order-price-info' | translate }}</span>
          <span class="min-order-inof-price">
            {{ calculateMinOrderPrice() | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}.
          </span>
          <span class="min-order-inof-price">{{ 'tapp-order.order-component.add-more-articles' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #summaryMode>
    <ng-container *ngIf="deliveryZone && this.orderService.getOrderType() === deliveryOrderType">
      {{ sessionStorageNewOrder.deliveryAdress }}
      <div class="container address-container">
        <div class="row">
          <div class="col-lg-12 main-label mb-2">
            {{ 'tapp-order.sidebar-component.delivery-adress' | translate }}
          </div>
          <div class="col-lg-6 left-side">
            <span class="city mr-1">{{ this.orderService.getDeliveryAddressCity() }},</span>
            <span class="street mr-1">ul. {{ this.orderService.getDeliveryAddressStreet() }}</span>
            <span class="number">{{ this.orderService.getDeliveryAddressBuildingNo() }}</span>
          </div>
          <div class="col-lg-6 right-side text-lg-right" *ngIf="this.orderService.getDeliveryAddressLocalNo()">
            <span class="label mr-1">{{ 'tapp-order.sidebar-component.local-no' | translate }}</span
            ><span class="number">{{ this.orderService.getDeliveryAddressLocalNo() }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container delivery-container">
      <div class="row">
        <div class="col-lg-12">
          <p class="delivery mb-0">
            <span class="label">{{ 'tapp-order.sidebar-component.food-cost' | translate }}:</span>
            <span>{{ basketService.foodTotalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </p>
          <p class="delivery mb-0">
            <span class="label">{{ 'tapp-order.sidebar-component.delivery-cost' | translate }}:</span>
            <span>{{ basketService.deliveryCost$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="container summary-container">
      <div class="row">
        <div class="col-lg-12">
          <p class="summary mb-0">
            <span class="label mr-1">{{ 'tapp-order.sidebar-component.summary' | translate }}:</span>
            <span class="amount">{{ basketService.finalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </p>
          <p class="payment mb-0">
            <span class="label">{{ 'tapp-order.order-success-payment-method-text' | translate }}:</span
            ><span class="type ml-1">{{
              sessionStorageNewOrder?.orderPayment.paymentMethod
                ? ('tapp-order.payment.payment-method.' + sessionStorageNewOrder?.orderPayment.paymentMethod
                  | lowercase
                  | translate)
                : '...'
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #isClose>
  <div class="sidebar-wrapper">
    <div class="sidebar-name">
      <span>{{ 'tapp-order.sidebar-basket-header' | translate }}</span>
    </div>
    <div class="sidebar-list">
      <ng-container>
        <div class="empty-basket d-flex align-items-center flex-column justify-content-center h-100">
          <img src="./assets/icons/basket1.png" alt="" />
          <span>{{ 'tapp-order.sidebar-component.local-closed' | translate }}</span>
          <span *ngIf="nextWorkingHours" style="color: black; font-size: medium; padding-top: 1rem">
            {{ 'tapp-order.sidebar-component.local-opening' | translate }}:
            <span style="color: var(--primary)">
              {{ 'app.weekDays.' + nextWorkingHours.weekDay | translate }}
              {{ nextWorkingHours.startTime }}
            </span>
          </span>
        </div>
      </ng-container>
    </div>
    <div>
      <p
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #8d9091;
          margin-bottom: 0.25rem;
        "
      >
        {{ 'tapp-order.sidebar-basket-local-info' | translate }}
      </p>
      <p
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: var(--primary);
          cursor: pointer;
        "
        class="local-name"
        (click)="changeLocal()"
      >
        {{ placeString ? placeString : null }}
      </p>
    </div>
  </div>
</ng-template>

<app-dialog-address-finder
  [displayDialog]="displayDialog"
  [redirectToPlace]="true"
  (onCloseDialog)="closeDialog()"
></app-dialog-address-finder>

<p-dialog
  [(visible)]="displayConfirmDialog"
  [style]="{ 'max-width': '500px' }"
  [draggable]="false"
  [modal]="true"
  [showHeader]="false"
>
  <div class="confirm-dialog">
    <span class="confirm-dialog-title">{{ 'tapp-order.sidebar-component.confirm-dialog-title' | translate }}</span>
    <span class="confirm-dialog-subtitle"
      >{{ 'tapp-order.sidebar-component.confirm-dialog-subtitle' | translate }}
      {{
        deliveryZone && deliveryZone.minOrderPriceForFreeDelivery
          ? (calculateFreeDeliveryCost() | currency: 'PLN':'zł':'1.2-2':'pl-PL')
          : null
      }}
      {{ 'tapp-order.sidebar-component.confirm-dialog-subtitle-2' | translate }}
      .
    </span>
    <p-divider [ngStyle]="{ width: '100%' }"></p-divider>

    <p class="confirm-dialog-description">
      {{ 'tapp-order.sidebar-component.confirm-dialog-description' | translate }}
    </p>
    <button class="save-button ui-clickable" (click)="closeConfirmDialog()">
      {{ 'tapp-order.sidebar-component.save-button' | translate }}
    </button>
    <button class="cancel-button ui-clickable" (click)="createOrder()">
      {{ 'tapp-order.sidebar-component.cancel-button' | translate }}
    </button>
  </div>
</p-dialog>

<app-unexpected-issue-dialog
  [visible]="displayPosStatusDialog"
  [place]="displayPosStatusDialogPlace"
  (visibleChange)="!$event && closePosStatusDialog()"
></app-unexpected-issue-dialog>
