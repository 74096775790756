import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss'],
})
export class AppSelectLangComponent implements OnInit {
  public locales: { name: string; code: string }[] = [
    {
      name: 'Polski',
      code: 'pl',
    },
    {
      name: 'English',
      code: 'en',
    },
  ];
  public currentLocale: { name: string; code: string } = null;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentLocale =
      this.locales.find((locale) => locale.code.toLowerCase() == this.translateService.currentLang.toLowerCase()) ??
      this.locales[0];
  }

  onLocaleChange() {
    this.translateService.use(this.currentLocale.code.toLowerCase());
  }
}
