<div class="payment-delivery-address-component" *ngIf="!isLoading">
  <div class="row">
    <ng-container
      *ngIf="
        ((isDeliveryZone?.getValue() && orderService.getOrderType() === deliveryOrderType) || isAccountPage) && !tableId
      "
    >
      <div
        class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column justify-content-between mb-4 mb-md-0"
      >
        <div class="row mb-2">
          <div class="col">
            <h2 class="header d-flex align-items-center justify-content-between">
              <span class="payment-delivery-address-title">{{
                'tapp-order.payment.delivery-adress.title' | translate
              }}</span>

              <span (click)="changeAddress()" class="payment-delivery-address-complete-data">{{
                'tapp-order.payment.delivery-adress.completeData' | translate
              }}</span>
            </h2>
          </div>
        </div>
        <div
          [ngClass]="paymentFormService.paymentAddressRejected && !isAccountPage ? 'border-red' : ''"
          class="address"
          (click)="changeAddress()"
        >
          <div class="address-item city">
            <span *ngIf="city">{{ city }}</span>
            <span class="placeholder" *ngIf="!city">{{
              'tapp-order.payment.delivery-adress.enterCity' | translate
            }}</span>
          </div>

          <div class="item-wrapper">
            <div class="address-item street">
              <span *ngIf="street">{{ street }}</span>
              <span class="placeholder" *ngIf="!street">{{
                'tapp-order.payment.delivery-adress.enterStreet' | translate
              }}</span>
            </div>
            <div class="address-item buildingNo">
              <span *ngIf="buildingNo">{{ buildingNo }}</span>
              <span class="placeholder" *ngIf="!buildingNo">{{
                'tapp-order.payment.delivery-adress.enterHouseNumber' | translate
              }}</span>
            </div>
          </div>

          <div class="address-item localNo">
            <span *ngIf="localNo">{{ 'tapp-order.payment.delivery-adress.local-no' | translate }}: {{ localNo }}</span>
            <span class="placeholder" *ngIf="!localNo">{{
              'tapp-order.payment.delivery-adress.enterFlatNumber' | translate
            }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-between flex-column">
      <div class="row mb-2">
        <div class="col">
          <h2 class="header d-flex align-items-center justify-content-between">
            <span class="payment-delivery-address-title">{{
              'tapp-order.payment.contact-data.title' | translate
            }}</span>

            <span (click)="showCustomerForm()" class="payment-delivery-address-complete-data">{{
              'tapp-order.payment.delivery-adress.completeData' | translate
            }}</span>
          </h2>
        </div>
      </div>
      <div
        class="customer-card-container"
        [ngClass]="paymentFormService.paymentCustomerRejected ? 'border-red' : ''"
        (click)="showCustomerForm()"
      >
        <ng-container
          *ngIf="
            orderService.getCustomerPhone() && orderService.getCustomerFirstName() && orderService.getCustomerEmail()
          "
        >
          <p class="delivery-customer-name">
            {{ orderService.getCustomerFirstName() }}
          </p>
          <p class="delivery-customer-phone">
            {{ orderService.getCustomerPhone() }}
          </p>
          <p class="delivery-customer-email">
            {{ orderService.getCustomerEmail() }}
          </p>
        </ng-container>

        <ng-container *ngIf="isAccountPage">
          <p
            *ngIf="
              !orderService.getCustomerPhone() ||
              !orderService.getCustomerFirstName() ||
              !orderService.getCustomerEmail()
            "
            class="delivery-customer-unknow"
          >
            {{ 'tapp-order.payment.contact-data.no-data' | translate }}
          </p>
        </ng-container>
        <ng-container
          *ngIf="
            !isAccountPage &&
            (!orderService.getCustomerPhone() ||
              !orderService.getCustomerFirstName() ||
              !orderService.getCustomerEmail())
          "
        >
          <p class="delivery-customer-unknow">
            {{ 'tapp-order.payment.contact-data.no-data' | translate }}
          </p>
          <p *ngIf="!user" class="login-message" (click)="openLoginDialog($event)">
            {{ 'tapp-order.payment.contact-data.haveAccount' | translate }}
            <span class="primary-color">{{ 'tapp-order.payment.contact-data.logIn' | translate }}</span>
          </p>
        </ng-container>
      </div>
    </div>
  </div>

  <app-dialog-address-finder
    [displayDialog]="displayDialog"
    [redirectToPlace]="false"
    [checkCurrentPlace]="!isAccountPage"
    [message1]="
      isAccountPage ? null : ('tapp-order.select-adress-component.dialog.adress-change-warning-2' | translate)
    "
    [message2]="
      isAccountPage ? null : ('tapp-order.select-adress-component.dialog.adress-change-warning-3' | translate)
    "
    (onCloseDialog)="closeDialog()"
    [context]="isAccountPage ? 'account' : 'other'"
  ></app-dialog-address-finder>

  <ng-container *ngIf="displayCustomerForm">
    <p-dialog
      [(visible)]="displayCustomerForm"
      [draggable]="false"
      [modal]="true"
      [showHeader]="false"
      [style]="{ width: '490px', height: '500px' }"
      [resizable]="false"
    >
      <span
        class="dialog-customer-form-title"
        style="display: flex; align-content: center; align-items: center; justify-content: center"
        >{{ 'tapp-order.payment.contact-data.dialog.title' | translate }}</span
      >
      <form [formGroup]="customerForm">
        <div class="form-group">
          <label class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.name.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('name').invalid && this.customerForm.get('name').touched }"
            type="text"
            formControlName="name"
            appInput
            pInputText
            maxlength="50"
            id="name"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.name.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
        <div class="form-group">
          <label for="phone" class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.phone.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('phone').invalid && this.customerForm.get('phone').touched }"
            type="text"
            formControlName="phone"
            appInput
            pInputText
            maxlength="15"
            id="phone"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.phone.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
        <div class="form-group">
          <label for="email" class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.email.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('email').invalid && this.customerForm.get('email').touched }"
            type="text"
            formControlName="email"
            appInput
            pInputText
            maxlength="100"
            id="email"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.email.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
      </form>
      <div class="buttons-container">
        <button class="save-button ui-clickable" (click)="saveCustomerInfo()">
          {{ 'tapp-order.payment.contact-data.dialog.form.save-button' | translate }}
        </button>
        <button class="cancel-button ui-clickable" (click)="cancelCustomerForm()">
          {{ 'tapp-order.payment.contact-data.dialog.form.cancel-button' | translate }}
        </button>
      </div>
    </p-dialog>
  </ng-container>
</div>
