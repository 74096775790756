<div class="payment-component payment-method">
  <div class="row">
    <ng-container *ngIf="paymentMethods">
      <ng-container *ngFor="let paymentMethod of paymentMethods; let i = index">
        <div class="col-lg-6 p-field-radiobutton">
          <label
            [ngClass]="paymentFormService.paymentMethodRejected ? 'border-red' : ''"
            for="{{ paymentMethod.name }}"
            class="d-flex align-items-center"
          >
            <p-radioButton
              name="licensePaymentMethod"
              [value]="paymentMethod.name"
              [(ngModel)]="selectedPaymentMethod"
              [inputId]="paymentMethod.name"
              [ngClass]="'radio '"
              (ngModelChange)="selectPaymentMethod()"
            ></p-radioButton>

            <!-- ROBOCZO -->
            <ng-container *ngIf="paymentMethod.name === 'ONLINE'">
              <span [class.active]="selectedPaymentMethod == paymentMethod.name" class="label">{{
                'tapp-order.payment.payment-method.online' | translate
              }}</span>
              <img
                *ngIf="paymentProvider == paymentProviderEnum.PayU"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/payu.png"
                [style]="'max-width: 66px'"
              />
              <img
                *ngIf="paymentProvider == paymentProviderEnum.Tpay"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/tpay.svg"
                [style]="'max-width: 45px'"
              />
            </ng-container>
            <ng-container *ngIf="paymentMethod.name === 'CASH'">
              <span [class.active]="selectedPaymentMethod == paymentMethod.name" class="label">{{
                'tapp-order.payment.payment-method.cash' | translate
              }}</span>
              <i class="ic ic-cash license-payment-card-payment-method-image ml-auto"></i>
            </ng-container>
            <ng-container *ngIf="paymentMethod.name === 'CARD'">
              <span [class.active]="selectedPaymentMethod == paymentMethod.name" class="label">{{
                'tapp-order.payment.payment-method.card' | translate
              }}</span>
              <img
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/credit-card.png"
                [style]="'max-width: 82px'"
              />
            </ng-container>
            <ng-container *ngIf="paymentMethod.name === 'BLIK'">
              <span [class.active]="selectedPaymentMethod == paymentMethod.name" class="label">{{
                'tapp-order.payment.payment-method.blik' | translate
              }}</span>
              <img
                *ngIf="paymentProvider == paymentProviderEnum.PayU"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/payu.png"
                [style]="'max-width: 66px'"
              />
              <img
                *ngIf="paymentProvider == paymentProviderEnum.Tpay"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/tpay.svg"
                [style]="'max-width: 45px'"
              />
            </ng-container>
            <ng-container *ngIf="paymentMethod.name === 'CARD_TOKEN'">
              <span [class.active]="selectedPaymentMethod == paymentMethod.name" class="label">{{
                'tapp-order.payment.payment-method.card_token' | translate
              }}</span>
              <img
                *ngIf="paymentProvider == paymentProviderEnum.PayU"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/payu.png"
                [style]="'max-width: 66px'"
              />
              <img
                *ngIf="paymentProvider == paymentProviderEnum.Tpay"
                class="license-payment-card-payment-method-image ml-auto"
                src="assets/images/payment-method/tpay.svg"
                [style]="'max-width: 45px'"
              />
            </ng-container>
          </label>

          <ng-container *ngIf="paymentMethod.name === 'BLIK'">
            <div class="row" *ngIf="selectedPaymentMethod === 'BLIK'">
              <div class="col-12">
                <input
                  [ngClass]="paymentFormService.paymentBlikRejected ? 'border-red' : ''"
                  type="number"
                  class="w-100 mt-2 blik-input"
                  pInputText
                  [(ngModel)]="blikCode"
                  (keyup)="setSelectedPaymentValue(); numberOnly($event)"
                  (keypress)="numberOnly($event)"
                  (change)="trimBlikCode($event)"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="6"
                  trim
                  style="color: var(--font-primary) !important"
                />
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="paymentMethod.name === 'CARD_TOKEN'">
            <div class="row" *ngIf="selectedPaymentMethod === 'CARD_TOKEN'">
              <div class="col-12">
                <app-credit-card [payuPaymentMethods]="payuPaymentMethods"></app-credit-card>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="paymentMethod.name === 'ONLINE'">
            <div *ngIf="selectedPaymentMethod === 'ONLINE'">
              <div class="row">
                <ng-container *ngIf="paymentProvider == paymentProviderEnum.PayU">
                  <div
                    class="col-4 my-auto ui-clickable payu-payment-method"
                    (click)="selectPaymentMethodValue(onlinePaymentMethod.value)"
                    *ngFor="let onlinePaymentMethod of payuPaymentMethods.paymentMethods"
                    [ngClass]="{
                      'payu-payment-method-selected': selectedPaymentMethodValue == onlinePaymentMethod.value
                    }"
                  >
                    <img [src]="onlinePaymentMethod.brandImageUrl" class="payu-payment-method-brand-image" />
                  </div>
                </ng-container>

                <ng-container *ngIf="paymentProvider == paymentProviderEnum.Tpay">
                  <div
                    class="col-4 d-flex my-auto ui-clickable payu-payment-method"
                    (click)="selectPaymentMethodValue(onlinePaymentMethod.id)"
                    *ngFor="let onlinePaymentMethod of tpayPaymentMethods.paymentMethods"
                    [ngClass]="{
                      'payu-payment-method-selected': selectedPaymentMethodValue == onlinePaymentMethod.id
                    }"
                  >
                    <img [src]="onlinePaymentMethod.img" class="payu-payment-method-brand-image" />
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
