import { DOCUMENT, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupViewModel } from '@core/models/tapp-order/view-model/menu/group.view.model';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { LoadingService } from '@shared/loading/loading.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/services/navigation.service';
import { appUtils } from 'src/app/utils/app.utils';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from '../../../../../services/theme.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.scss'],
})
export class ProductGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  /* Required */
  @Input() groups: GroupViewModel[] = [];

  /* Optional */
  @Input() page: 'products' | 'discounts' | 'productModify' = 'products';
  @Input() hasDiscounts: boolean = true;
  @Input() qrMenuMode: boolean = true;

  private toolbarMouseDown = false;
  private toolbarStartX: number = 0;
  private toolbarScrollLeft: number = 0;
  private startShift = 0;
  private toolbarScrollingStartTimeout?: NodeJS.Timeout;

  private destroyed$ = new Subject<void>();

  private viewInitSource = new BehaviorSubject<boolean>(null);
  private viewInit$ = this.viewInitSource.asObservable().pipe(
    filter((data) => data === true),
    first(),
    takeUntil(this.destroyed$),
  );

  public chosenProductGroupId: string = '';
  public action: 'choosenProductGroup' | 'scrollPage' = 'scrollPage';
  public toolbarScrolling = false;

  private _iconColor = '#a1a1a1';
  private _iconPrimaryColor = '#2c60a2';

  constructor(
    private viewportScroller: ViewportScroller,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.handleQueryParams();

    let bodyStyle = window.getComputedStyle(this.document.body);
    this._iconPrimaryColor = bodyStyle.getPropertyValue('--primary');
    this._iconColor = bodyStyle.getPropertyValue('--icon-fill');
  }

  ngAfterViewInit(): void {
    this.viewInitSource.next(true);
    this.onScrollEvent();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    if (this.action === 'scrollPage') {
      this.onScrollGroups();
    }
  }

  private handleQueryParams(): void {
    if (this.page === 'discounts' || this.page === 'productModify') {
      return;
    }

    const selectedProductGroupId = this.route.snapshot.queryParams[ParamKeys.CurrentProductGroupId];

    if (selectedProductGroupId) {
      this.loadingService.showLoader();

      setTimeout(() => {
        const tryScrollToGroup = (productGroupId: string) => {
          if (this.isToolbarProductGroupsRendered() && this.isProductGroupRendered(productGroupId)) {
            this.loadingService.hideLoader();
            this.chooseProductGroup(productGroupId);
          } else {
            setTimeout(() => {
              tryScrollToGroup(productGroupId);
            });
          }
        };

        this.viewInit$.subscribe((data) => {
          tryScrollToGroup(selectedProductGroupId);
        });
      }, 50);
    } else {
      this.loadingService.hideLoader();
      this.chosenProductGroupId = this.groups[0].publicId;
    }
  }

  private scrollTolbar(productGroupIndex: number, mode: 'begin' | 'next' | 'previous' | 'forward' | 'backward') {
    const id = this.groups[productGroupIndex].publicId;
    const toolbarMenu = document.getElementById('toolbar-menu');
    const menuitemhtmlel = document.getElementById('menu-item' + id);
    const toolbarBounding = toolbarMenu.getBoundingClientRect();
    const elbounding = menuitemhtmlel.getBoundingClientRect();

    const appPaddingWidth = getComputedStyle(document.documentElement).getPropertyValue('--app-padding-width');

    if (appPaddingWidth === null) {
      console.error('Variable "--app-padding-width" not found #SANDF02JFDSFN230FJ');
      return;
    }

    let appPaddingWidthNumber = -Number(appPaddingWidth.replace('px', ''));

    switch (mode) {
      case 'begin':
        toolbarMenu.scrollTo({
          left: appPaddingWidthNumber + 0,
          behavior: 'smooth',
        });
        break;

      case 'next':
        toolbarMenu.scrollTo({
          left: appPaddingWidthNumber + toolbarMenu.scrollLeft + elbounding.left - 30,
          behavior: 'smooth',
        });
        break;

      case 'previous':
        toolbarMenu.scrollTo({
          left: appPaddingWidthNumber + toolbarMenu.scrollLeft - elbounding.right,

          behavior: 'smooth',
        });
        break;

      case 'forward':
        toolbarMenu.scrollTo({
          left:
            appPaddingWidthNumber +
            toolbarMenu.scrollLeft +
            (toolbarBounding.width - (toolbarBounding.width / 100) * 20),
          behavior: 'smooth',
        });
        break;

      case 'backward':
        toolbarMenu.scrollTo({
          left:
            appPaddingWidthNumber +
            toolbarMenu.scrollLeft -
            (toolbarBounding.width - (toolbarBounding.width / 100) * 20),
          behavior: 'smooth',
        });
        break;
    }
  }

  public scrollTolbarNext() {
    this.scrollTolbar(this.getCurrentProductGroupIndex(), 'forward');
  }

  public scrollTolbarPrevious() {
    this.scrollTolbar(this.getCurrentProductGroupIndex(), 'backward');
  }

  public toolbarStartDragging(e, flag, el) {
    if (appUtils.isMobileDevice()) {
      return;
    }

    this.toolbarScrolling = false;
    clearTimeout(this.toolbarScrollingStartTimeout);
    clearTimeout(this.toolbarScrollingStopTimeout);

    this.toolbarScrollingStartTimeout = setTimeout(() => {
      this.toolbarScrolling = false;
      this.toolbarScrollingStartTimeout = undefined;
    }, 250);

    this.startShift = e.pageX;

    this.toolbarMouseDown = true;
    this.toolbarStartX = e.pageX - el.offsetLeft;
    this.toolbarScrollLeft = el.scrollLeft;
    /* Przy skrolowaniu ręcznym trzeba aktualizować element do którego obecnie jest przewinięty ekran */
  }

  private toolbarScrollingStopTimeout?: NodeJS.Timeout;
  public toolbarStopDragging(e, flag) {
    if (appUtils.isMobileDevice()) {
      return;
    }

    this.toolbarMouseDown = false;
    clearTimeout(this.toolbarScrollingStartTimeout);
    clearTimeout(this.toolbarScrollingStopTimeout);

    this.toolbarScrollingStopTimeout = setTimeout(() => {
      this.toolbarScrolling = false;
      this.toolbarScrollingStopTimeout = undefined;
    }, 25);
  }

  public toolbarMoveEvent(e, el) {
    if (appUtils.isMobileDevice()) {
      return;
    }

    e.preventDefault();
    if (!this.toolbarMouseDown) {
      return;
    }
    if (this.startShift && (this.startShift > e.pageX + 25 || this.startShift < e.pageX - 25)) {
      clearTimeout(this.toolbarScrollingStartTimeout);
      this.toolbarScrollingStartTimeout = undefined;
      this.toolbarScrolling = true;
    }

    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.toolbarStartX;
    el.scrollLeft = this.toolbarScrollLeft - scroll;
  }

  public redirectToDiscounts() {
    if (this.toolbarScrolling || this.page === 'discounts') {
      return;
    }

    this.chosenProductGroupId = '';
    this.loadingService.showLoader();

    const placeId = sessionStorage.getItem('placeId');

    setTimeout(() => {
      if (this.qrMenuMode) {
        this.router.navigate([placeId, 'discounts', 'qrmenu']);
      } else {
        this.router.navigate([placeId, 'discounts']);
      }
    }, 100);
  }

  public onScrollEvent() {
    const toolbarMenu = document.getElementById('toolbar-menu');
    const arrowBack = document.querySelector('.toolbar-arrow--backward');
    const arrowNext = document.querySelector('.toolbar-arrow--forward');
    if (toolbarMenu.scrollLeft <= 5) {
      /* Ukrywanie strzałki cofającej */
      if (arrowBack.classList.contains('visible')) {
        arrowBack.classList.remove('visible');
      }
      if (!arrowBack.classList.contains('invisible')) {
        arrowBack.classList.add('invisible');
      }
    } else {
      /* Pokazywanie strzałki cofającej */
      if (!arrowBack.classList.contains('visible')) {
        arrowBack.classList.add('visible');
      }
      if (arrowBack.classList.contains('invisible')) {
        arrowBack.classList.remove('invisible');
      }
    }

    if (toolbarMenu.scrollLeft >= toolbarMenu.scrollWidth - toolbarMenu.clientWidth - 5) {
      /* Ukrywanie strzałki przewijajacej */
      if (arrowNext.classList.contains('visible')) {
        arrowNext.classList.remove('visible');
      }
      if (!arrowNext.classList.contains('invisible')) {
        arrowNext.classList.add('invisible');
      }
    } else {
      /* Pokazywanie strzałki przewijajacej */
      if (!arrowNext.classList.contains('visible')) {
        arrowNext.classList.add('visible');
      }
      if (arrowNext.classList.contains('invisible')) {
        arrowNext.classList.remove('invisible');
      }
    }
  }

  private isToolbarProductGroupsRendered(): boolean {
    if (!this.groups?.length) {
      return false;
    }

    const groupElement = document.getElementById('menu-item' + this.groups[0].publicId);

    if (groupElement) {
      return true;
    } else {
      return false;
    }
  }

  private isProductGroupRendered(productGroupId: string): boolean {
    const groupElement = document.getElementById(productGroupId);

    return !!groupElement;
  }

  private getCurrentProductGroupIndex(): number {
    const groupElement = document.getElementById(
      'menu-item' + (this.chosenProductGroupId != '' ? this.chosenProductGroupId : this.groups[0].publicId),
    );

    if (groupElement) {
      const index = groupElement.getAttribute('index');
      return Number(index);
    } else {
      console.error('App internal error. Attribute "index" not found #2MNDX0OG02JDSZQA');
      return -1;
    }
  }

  private scrollPageToProductGroup(productGroupId: string): void {
    const appNavbar = document.getElementById('app-navbar');
    const groupElement = document.getElementById(productGroupId);

    if (groupElement && appNavbar) {
      setTimeout(() => {
        const topPosition = groupElement.offsetTop;
        const offset = appNavbar.getBoundingClientRect().bottom + 20;
        this.viewportScroller.scrollToPosition([0, topPosition - offset]);

        // This code is used to check if the page has been scrolled to the desired position.
        const expectedPosition = topPosition - offset;
        let previousScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const checkScrollInterval = setInterval(() => {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

          if (currentScrollTop === expectedPosition || previousScrollTop === currentScrollTop) {
            clearInterval(checkScrollInterval);
            this.action = 'scrollPage';
          }
          previousScrollTop = currentScrollTop;
        }, 50);
      });
    }
  }

  private onScrollGroups(): void {
    const firstVisibleProductGroupId = this.getFirstVisibleProductGroupId();

    if (firstVisibleProductGroupId) {
      this.chosenProductGroupId = firstVisibleProductGroupId;
      this.scrollTolbar(this.getCurrentProductGroupIndex(), 'next');

      this.navigationService.updateCurrentQueryParams({
        [ParamKeys.CurrentProductGroupId]: firstVisibleProductGroupId,
      });
    }
  }

  private getFirstVisibleProductGroupId(): string | undefined {
    const appNavbar = document.getElementById('app-navbar');
    const groups = document.querySelectorAll('.group');

    const offset = appNavbar.getBoundingClientRect().bottom + 20;

    for (let group of Array.from(groups)) {
      const rect = group.getBoundingClientRect();

      // Check if the group is at least partially visible in the viewport
      const isPartiallyVisible = rect.top < window.innerHeight && rect.bottom > offset;

      if (isPartiallyVisible) {
        return group.getAttribute('id'); // Return the group if it is visible
      }
    }

    return undefined;
  }

  public chooseProductGroup(productGroupId: string): void {
    if (this.toolbarScrolling) {
      return;
    }

    if (this.page === 'discounts' || this.page === 'productModify') {
      this.chosenProductGroupId = productGroupId;
      this.loadingService.showLoader();

      setTimeout(() => {
        if (this.qrMenuMode) {
          this.router.navigate([sessionStorage.getItem('placeId') ?? '/', 'products', 'qrmenu'], {
            queryParams: { [ParamKeys.CurrentProductGroupId]: productGroupId },
          });
        } else {
          this.router.navigate([sessionStorage.getItem('placeId') ?? '/', 'products'], {
            queryParams: { [ParamKeys.CurrentProductGroupId]: productGroupId },
          });
        }
      }, 100);

      return;
    }

    this.navigationService.updateCurrentQueryParams({
      [ParamKeys.CurrentProductGroupId]: productGroupId,
    });

    this.chosenProductGroupId = productGroupId;
    this.action = 'choosenProductGroup';
    this.scrollTolbar(this.getCurrentProductGroupIndex(), 'next');

    this.scrollPageToProductGroup(productGroupId);
  }

  getGroupIcon(url: string, primary: boolean) {
    if (url.endsWith('.svg')) {
      let filename = url.split('/').pop();
      let subdomain = sessionStorage.getItem('subdomain');
      if (subdomain) {
        return (
          environment.apiUrl +
          `/core/svg/${subdomain}/image/${filename}?fill=` +
          encodeURIComponent(primary ? this._iconPrimaryColor : this._iconColor)
        );
      }
    }

    return url;
  }
}
